<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${charge.name}` }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("CHARGES.CATEGORY") }}</dt>
        <dd class="col-sm-8 text-uppercase" v-if="charge?.category">
          {{ $t("CHARGES." + charge?.category) }}
        </dd>
      </dl>
      <dl class="row" v-if="charge.name">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ charge.name ?? "" }}
        </dd>
      </dl>
      <dl class="row" v-if="charge.amount">
        <dt class="col-sm-4">{{ $t("CHARGES.AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ '$'+charge.amount ?? "" }}
        </dd>
      </dl>
      <dl class="row" v-if="charge.percentage">
        <dt class="col-sm-4">{{ $t("CHARGES.PERCENTAGE") }}</dt>
        <dd class="col-sm-8">
          {{ '%'+charge.percentage ?? "" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ charge.created_at | moment("LLLL") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ charge.updated_at | moment("LLLL") }}
        </dd>
      </dl>
      <div class="paliers my-1" v-if="charge.tiers && charge.tiers.length">
        <tiers-list :tiers="charge.tiers" :title="$t('CHARGES.PALIERS')" currency="$" />
      </div>
    </div>
  </div>
</template>

<script>
import TiersList from "../components/TiersList.vue";
export default {
  name: "charge-view-global",
  components:{
    TiersList
  },
  props: ["charge"],

  data() {
    return {};
  },

  created() {},

  methods: {
    chargeUpdated() {
      this.$emit("chargeUpdated", true);
    },
  },

  mounted() {},

  watch: {
    charge(charge) {},
  },
};
</script>
