<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!charge">
      <span class="loader"></span>
    </span>
    <div v-if="charge" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
      >
        <tab-pane title="label" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <charge-view-global :charge="charge" @onChargeUpdated="get" />
        </tab-pane>
        <tab-pane v-if="charge.commission" title="label" id="2">
          <span slot="title">
            <charge-icon :width="'20px'" class="custom-svg-icon"/>
            {{ $t("CHARGES.COMMISSION") }}
          </span>
          <charge-view-global :charge="charge.commission" @onChargeUpdated="get" />
        </tab-pane>
        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <charge-view-logs :charge="charge" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ChargeViewLogs from "../partials/ChargeViewLogs.vue";
import ChargeViewGlobal from "../partials/ChargeViewGlobal.vue";
import ChargeIcon from '@/components/Icons/ChargeIcon.vue';

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ChargeViewLogs,
    ChargeViewGlobal,
    ChargeIcon
  },

  mixins: [],

  props: {
    chargeId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      charge: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("charges/get", this.chargeId);
        this.charge = this.$store.getters["charges/charge"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
