<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CHARGES.CHARGES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button btn-black add"
              icon
              size="sm"
              @click="openChargePackages"
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGE_PACKAGES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-folder"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CHARGE_PACKAGES.CHARGE_PACKAGES")
              }}</span>
            </base-button>
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openChargeCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CHARGES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CHARGES.ADD_CHARGE")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGES)"
              :objectType="'charges'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <charge-list-table
        @onViewCharge="openChargeViewModal"
        @onEditCharge="openChargeEditModal"
        @onDeleteCharge="deleteCharge"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewChargeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewChargeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CHARGE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGES.VIEW_CHARGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openCharge"
                :objectType="'charges'"
                :objectId="openCharge.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openChargeEditModal(openCharge)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteCharge(openCharge)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeChargeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-charge-component
              v-if="openCharge"
              :chargeId="openCharge.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditChargeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditChargeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CHARGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGES.EDIT_CHARGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openChargeViewModal(openCharge)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeChargeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-charge-component
              v-if="openCharge"
              :chargeId="openCharge.id"
              @onViewCharge="openChargeViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddChargeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddChargeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CHARGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGES.ADD_CHARGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeChargeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-charge-component @onViewCharge="openChargeViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ChargeListTable from "./partials/ChargeListTable.vue";
import EditChargeComponent from "./components/EditChargeComponent.vue";
import AddChargeComponent from "./components/AddChargeComponent.vue";
import ViewChargeComponent from "./components/ViewChargeComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ChargeListTable,
    NotificationSubscription,
    EditChargeComponent,
    AddChargeComponent,
    ViewChargeComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const chargeId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewChargeModalOpened = false;
    let isEditChargeModalOpened = false;
    let isAddChargeModalOpened = false;
    let openCharge = null;
    if (chargeId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewChargeModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditChargeModalOpened = true;
      }
      openCharge = { id: chargeId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddChargeModalOpened = true;
    }
    return {
      isViewChargeModalOpened: isViewChargeModalOpened,
      isEditChargeModalOpened: isEditChargeModalOpened,
      isAddChargeModalOpened: isAddChargeModalOpened,
      openCharge: openCharge,
      renderKey: 1,
    };
  },

  methods: {
    openChargeCreateModal() {
      this.closeChargeModal();
      this.isAddChargeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Charges",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openChargePackages(){
      this.$router.push({
        name: "List ChargePackages"
      });
    },
    openChargeViewModal(charge, reRender = false) {
      this.closeChargeModal();
      this.openCharge = charge;
      this.isViewChargeModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Charges",
          query: { id: this.openCharge.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openChargeEditModal(charge) {
      this.closeChargeModal();
      this.openCharge = charge;
      this.isEditChargeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Charges",
          query: { id: this.openCharge.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeChargeModal() {
      this.isAddChargeModalOpened = false;
      this.isViewChargeModalOpened = false;
      this.isEditChargeModalOpened = false;
      this.openCharge = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Charges",
          query: {},
        }).href
      );
    },

    async deleteCharge(charge) {
      const confirmation = await swal.fire({
        title: this.$t("CHARGES.DELETE_THIS_CHARGE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("charges/destroy", charge.id);
          this.renderKey++;
          this.closeChargeModal();
          this.$notify({
            type: "success",
            message: this.$t("CHARGES.CHARGE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
