<template>
  <div class="container-fluid">
    <charge-form
      :loading="loading"
      :chargeData="charge"
      :formErrors="formErrors"
      @chargeSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCharge from "../defaultCharge";
import ChargeForm from "../partials/ChargeForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ChargeForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      charge: cloneDeep(defaultCharge),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(charge) {
      this.loading = true;

      const chargeData = cloneDeep(charge);
      delete chargeData.id;

      try {
        await this.$store.dispatch("charges/add", chargeData);
        this.$notify({
          type: "success",
          message: this.$t("CHARGES.CHARGE_ADDED"),
        });
        const charge = await this.$store.getters["charges/charge"];
        this.$emit("onViewCharge", charge, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
