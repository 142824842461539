<template>
  <div class="paliers">
    <h4 class="my-3">{{ title }}</h4>
    <div class="page-wrapper-table-body-inner">
    <el-table
      v-if="tiers.length"
      :data="tiers"
      class="align-items-center table-flush"
      header-row-class-name="thead-light"
      border
    >
      <el-table-column :label="$t('CHARGES.RANGE')" min-width="150">
        <template v-slot="scope">
          <div>
            <strong>{{ $t("CHARGES.MIN") }}:</strong>
            {{ scope.row.min_value || 0 }}<br />
            <strong>{{ $t("CHARGES.MAX") }}:</strong>
            {{ scope.row.max_value || "∞" }}
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('CHARGES.COUT')" min-width="150">
        <template v-slot="scope">
          <div v-if="scope.row.amount">
            {{ currency }}{{ scope.row.amount }}
          </div>
          <div v-else-if="scope.row.percentage">
            {{ scope.row.percentage }}%
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
        <template v-slot="scope">
          <div>{{ scope.row.excerpt??'' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
export default {
  name: "TiersList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    tiers: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "$",
    },
  },
};
</script>

<style scoped>
.paliers {
  margin-top: 20px;
}
</style>
